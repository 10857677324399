import React, { useContext, useState, useCallback, useEffect } from 'react';
import * as S from './ToggleButtonV1.styles';
import { CSSTransition } from 'react-transition-group';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';
import useLocalStorage from '../../hooks/useLocalStorage';
import FunnelContext from '../../context/FunnelContext';

const ToggleButtonV1 = ({ builderBlock, extraObjects }) => {
  const [active, setActive] = useState(false);

  const { extraObjects: extraData, setExtraObjects } = useContext(
    FunnelContext
  );

  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});

  const extraObjectValue = (key, value, type, extra) => {
    let newVal;
    switch (type) {
      case 'list':
        const existing = extra[key] || [];
        newVal = [...new Set([...existing, value])];
        break;
      case 'boolean':
        newVal = value ? true : false;
        break;
      default:
      case 'string':
        newVal = value;
        break;
    }
    return { [key]: newVal };
  };

  const initiateData = useCallback(
    isActive => {
      const newExtraData = extraData;

      if (extraObjects?.length > 0) {
        extraObjects.map(({ key, value, type }) => {
          if (isActive) {
            Object.assign(
              newExtraData,
              extraObjectValue(key, value, type, newExtraData)
            );
          } else {
            switch (type) {
              case 'list':
                newExtraData[key].splice(newExtraData[key].indexOf(value), 1);
                break;
              default:
                delete newExtraData[key];
                break;
            }
          }
          return null;
        });
      }

      if (isActive) {
        Object.assign(newExtraData, { [builderBlock.id]: true });
      } else {
        Object.assign(newExtraData, { [builderBlock.id]: false });
      }

      setExtraObjects2(newExtraData);
      setExtraObjects(newExtraData);
    },
    [extraData, extraObjects, setExtraObjects, setExtraObjects2, builderBlock]
  );

  useEffect(() => {
    const isActive = extraObjects2[builderBlock.id] || false;
    setActive(isActive);
    const newExtraData = Object.assign(extraData, extraObjects2);
    setExtraObjects(newExtraData);
    setExtraObjects2(newExtraData);
    if (isActive) {
      initiateData(isActive);
    }
  }, [
    extraData,
    extraObjects2,
    setActive,
    builderBlock,
    setExtraObjects,
    setExtraObjects2,
    initiateData
  ]);

  const duration = 500;
  useButtonEventPressed('add-to-local-cart-press-trigger');

  const width = active ? 46 : 110;

  const handleClick = () => {
    const isActive = !active;
    setActive(isActive);
    initiateData(isActive);
  };

  return (
    <S.Container
      onClick={handleClick}
      className="add-to-local-cart-press-trigger"
    >
      <CSSTransition in={active} timeout={duration}>
        <>
          <S.RemoveText className={active ? `` : `hidden`}>Remove</S.RemoveText>
          <S.Button
            className={`button ${active ? `with-item` : ``}`}
            style={{ width: `${width}px` }}
          >
            <span className={active ? `hidden` : ``}>Add</span>
            <span className={`${active ? `` : `hidden`} svg`}>
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.45744 10.4338L18.3108 0.580485C19.0848 -0.193495 20.3351 -0.193495 21.119 0.580485L22.5082 1.96968C23.2821 2.74366 23.2821 4.00386 22.5082 4.77784L9.85656 17.4195C9.08258 18.1935 7.82238 18.1935 7.0484 17.4195L1.49162 11.8627C0.717638 11.0888 0.717638 9.82856 1.49162 9.05458L2.88081 7.66538C3.65479 6.8914 4.91499 6.8914 5.68897 7.66538L8.45744 10.4338Z"
                  fill="white"
                />
              </svg>
            </span>
          </S.Button>
        </>
      </CSSTransition>
    </S.Container>
  );
};

export default ToggleButtonV1;
